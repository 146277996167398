export const hiddenSides = (state = undefined, action) => {

  if(!state || action.type === "RESET")
  {
    console.log(window.innerWidth);
    state = [false, window.innerWidth < 850]
  }

  switch (action.type) {
    case "RIGHT_SIDE":
      return [state[0], !state[1]]
    case "LEFT_SIDE":
      return [!state[0], state[1]]
    default:
      return state
  }
}

export const currentMode = (state = "text", action) => {
  if (action.type === "TOGGLE_VIEW" && action.newMode !== undefined) {
    return action.newMode
  }
  if (action.type === "RESET_MODE") {
    return "text"
  }
  if (action.type === "RESET") {
    return "text"
  }
  return state
}

export const changeText = (state = [0, "", "", 0, "", "", true], action) => {
  if (action.type === "SET_CHAPTER_TEXT") {  
    return [
      action.chapterId, 
      action.chapterText,
      action.chapterName, 
      action.version, 
      action.logoUrl, 
      action.markers.length == 0 ? action.chapterText : getMarkedText(action.chapterText, action.markers),
      action.isVisible
    ]
  }
  if (action.type === "RESET" || action.type === 'RESET_SCHEMA') {
    return [0, "", "", 0, "", true]
  }
  if (action.type === "RESET_CHAPTER_TEXT") {
    return [state[0],"","",0,"", true]
  }
  
  return state
}

const getMarkedText = (text, markers) => {

  markers.forEach(marker => {
    text = insertIntoString(text, marker.startIndex, marker.startTag)
    text = insertIntoString(text, marker.startIndex + marker.startTag.length + marker.length, marker.endTag)
  });
  return text
  
}

const insertIntoString = (string, index, insertString) => {
  return string.slice(0, index) + insertString + string.slice(index);
}