// Import React!
import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { authenticateAndSendToAPI } from "../Fetching/connection";
import config from "../config";
import color_map from '../config/colorPickerMap'
var mammoth = require("../mammoth/lib");
var _ = require("lodash");
var dataUriToBuffer = require("data-uri-to-buffer");
var timeout = null;
var $ = require('jquery')
const axios = require("axios");
let modalInstance;

class TextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text[1],
      editorText: null,
      chapterId: this.props.text[0],
      chapterName: this.props.text[2],
      logoUrl: this.props.logoUrl,
      logoUrlFile: "",
      hasSaved: false,
      instances: [],
      oldImageWidth: 0,
      oldImageHeight: 0,
      oldImageSizeType: "px",
      currentInputType: undefined,
      currentImageId: 0,
      imageAlignment: "",
      webSocket: undefined,
      isVisible: this.props.text[6]
    };
  }

  handleChangeName = event => {
    this.setState({ chapterName: event.target.value });
  };

  handleChangeLogo = event => {
    this.setState({ logoUrl: event.target.value });
  };

  handleChangeLogoFile = event => {
    this.setState({ logoUrlFile: event.target.files[0] });
  };

  onChapterDelete = () => {
    if (
      window.confirm(
        "Möchten sie das Kapitel:\n" +
        this.state.chapterName +
        "\nWirklich entfernen?"
      )
    ) {
      this.props.onChapterDelete(this.props.text[0], this.props.textName);
      setTimeout(() => {
        this.props.changeToFirstChapter();
      }, 1000);
    }
  };

  prepareChapterText = (chapterText) => {
    chapterText = chapterText.replace(/target="_self"/g, 'target="_blank"');
    chapterText = chapterText.replace(/"www./g, '"http://www.');
    chapterText = chapterText.replace(
      /<a href="/g,
      '<a target="_blank" href="'
    );
    chapterText = chapterText.replace(/\\/g, "&#92;");
    chapterText = chapterText.replace(/ (?![^<]*>)/g, "&nbsp;<wbr />");
    chapterText = chapterText.replace(/&nbsp;(?!<wbr \/>)/g, "&nbsp;<wbr />");
    if (!navigator.userAgent.includes("Safari")) {
      eval("chapterText = chapterText.replace(/(?<!&nbsp;)<wbr \/>/g, \"&nbsp;<wbr />\")");
    }
    chapterText = chapterText.replace(/<wbr \/><wbr \/>/g, "<wbr />");

    return chapterText
  }

  onChapterEdit = silent => {
    const chapterId = this.state.chapterId;
    let chapterText;
    if (this.state.editorText === null) {
      chapterText = this.state.text;
    } else {
      chapterText = this.state.editorText;
    }

    this.setState({ hasSaved: true });
    this.state.editor.setDirty(false);
    let chapterName = this.state.chapterName.replace(/\(-\)/g, "­")

    chapterText = this.prepareChapterText(chapterText)
    const isVisible = this.state.isVisible

    if (this.state.logoUrlFile !== "") {
      let data = new FormData();
      data.append("File", this.state.logoUrlFile);
      data.append("textName", this.props.textName);
      data.append("chapterId", chapterId);
      data.append("displayText", "Logo");
      data.append("uploadType", "Logo");

      window
        .fetch(config.apiUrl + "/api/upload", {
          method: "POST",
          body: data
        })
        .then(response => {
          if (response.status === 201) {
            toast.success("Logo wurde erfolgreich hinzugefügt!");
            response.text().then(url => {
              this.props.onChapterEdit(
                chapterId,
                chapterName,
                chapterText,
                this.props.textName,
                url,
                silent,
                isVisible
              );
            });
          } else {
            response.json().then(response => toast.error(response.Message));
          }
        });
    } else {
      this.props.onChapterEdit(
        chapterId,
        chapterName,
        chapterText,
        this.props.textName,
        this.state.logoUrl,
        silent,
        isVisible
      );
    }
  };

  confirmSave = () => {
    const { editorText, text } = this.state;
    if (editorText !== null && text !== editorText && !this.state.hasSaved) {
      if (
        window.confirm(
          "Sie haben ungespeicherte Änderungen, möchten sie noch speichern?"
        )
      ) {
        this.onChapterEdit(false);
      }
    }
  };

  componentWillUnmount() {
    this.confirmSave();
  }

  uploadCallback = (blob, success, failure) => {
    const reader = new FileReader();
    const textName = this.props.textName;
    const chapterId = this.state.chapterId;

    console.log("upload tracked");

    reader.onload = filedata => {
      const query =
        `
        mutation
          {
            uploadImage(textName: "` +
        textName +
        `" chapterId:` +
        chapterId +
        ` DataUrl: "` +
        filedata.target.result.substring(
          filedata.target.result.indexOf(",") + 1
        ) +
        `")
          }
          `;
      authenticateAndSendToAPI(query, result => {
        if (result.uploadImage === "An error occured while saving!") {
          failure("Das Bild konnte nicht hochgeladen werden.");
          return;
        }
        success(result.uploadImage);
      });
    };
    reader.readAsDataURL(blob.blob());
  };

  saveRegularly() {
    if (timeout != null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {

      const { editorText, text, hasSaved } = this.state;
      if (editorText !== null && text !== editorText && !hasSaved) {
        this.onChapterEdit(true);
      }

      this.saveRegularly()
    }, 4000);
  }

  onEditorUpdate = (editorText, editor) => {
    this.setState({ editorText });
    this.sendCurrentTextToWs()
  };

  sendCurrentTextToWs = () => {
    if (this.state.webSocket && this.state.webSocket.readyState == 1) {
      let chapterText;
      if (this.state.editorText === null) {
        chapterText = this.state.text;
      } else {
        chapterText = this.state.editorText;
      }

      this.state.webSocket.send("updateText┐" + this.prepareChapterText(chapterText))
    }
  }

  componentDidMount() {
    this.saveRegularly()

    var elems = document.querySelectorAll(".modal");

    modalInstance = window.M.Modal.init(elems, {
      dismissible: false,
      startingTop: "10%"
    })[0];

    var component = this;
    function handleFileSelect(evt) {
      var files = evt.target.files; // FileList object
      var f = files[0];
      if (f === undefined) {
        return;
      }

      if (f.name.endsWith(".docx")) {
        var normalOutput =
          escape(f.name) + " - " + f.size + " bytes, zuletzt verändert: ";
        if (f.lastModified !== undefined) {
          var d = new Date(0);
          d.setUTCMilliseconds(f.lastModified);
          normalOutput += d.toLocaleDateString();
        } else {
          normalOutput += "unbekannt";
        }
        if (
          window.confirm(
            "Möchten sie den Editor Inhalt mit dem folgenden Document ersetzen? \n " +
            normalOutput
          )
        ) {
          var reader = new FileReader();
          reader.readAsDataURL(f);
          reader.addEventListener(
            "load",
            function () {
              var buffer = dataUriToBuffer(reader.result);
              var highlights = [],
                colors = [];
              function transformElement(element) {

                if (element.type === 'bookmarkStart') {
                  return {};
                }

                if (
                  element.numbering !== undefined &&
                  element.numbering !== null
                ) {
                  let element2 = JSON.parse(JSON.stringify(element));
                  element.numbering = null;
                  element2.type = "run";
                  element2.children = [element];
                  element2.alignment = undefined;
                  if (element2.numbering.isOrdered) {
                    element2.styleId = "orderedList";
                  } else {
                    element2.styleId = "unorderedList";
                  }
                  return element2;
                }

                if (element.children) {
                  var children = _.map(element.children, transformElement);
                  element = { ...element, children: children };
                }

                if (element.type === "text") {
                  element.value = element.value.replace(/ /g, "&nbsp;")
                  element.value = element.value.replace(/-/g, "&#8209;")
                }

                if (element.type === "paragraph") {
                  if (element.alignment === "center") {
                    return { ...element, styleId: "alignCenter" };
                  } else if (element.alignment === "right") {
                    return { ...element, styleId: "alignRight" };
                  } else {
                    return element;
                  }
                }

                if (element.type === "run") {
                  element.styleId = "";
                  if (element.highlight) {
                    element.styleId += "highlight";
                    highlights.push(element.highlight);
                  }
                  if (element.color) {
                    element.styleId += "color";
                    colors.push(element.color);
                  }
                }

                return element;
              }

              var styleMap = [
                "p.alignCenter => p.alignCenter:fresh",
                "p.alignRight => p.alignRight:fresh",
                "r.highlight => r.highlight:fresh",
                "r.color => r.color:fresh",
                "r.highlightcolor => r.highlight:fresh > r.color:fresh",
                "r.orderedList => ol > li:fresh",
                "r.unorderedList => ul > li:fresh",
                "p.überschrift1 => h5",
                "p.berschrift1 => h5"
              ];

              function hexToRgb(hex) {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                  hex
                );
                return result
                  ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                  }
                  : null;
              }

              mammoth
                .convertToHtml(
                  { arrayBuffer: buffer },
                  { transformDocument: transformElement, styleMap }
                )
                .then(function (result) {
                  var html = result.value; // The generated HTML
                  var index = 0;
                  while (html.includes('<r class="color">')) {
                    var color = hexToRgb(colors[index]);
                    index++;
                    html = html
                      .replace(
                        '<r class="color">',
                        '<span style="color:rgb(' +
                        color.r +
                        "," +
                        color.g +
                        "," +
                        color.b +
                        ')">'
                      )
                      .replace("</r>", "</span>");
                  }
                  index = 0;
                  while (html.includes('<r class="highlight">')) {
                    var highlight = highlights[index];
                    index++;
                    html = html
                      .replace(
                        '<r class="highlight">',
                        '<span style="background-color:' + highlight + '">'
                      )
                      .replace("</r>", "</span>");
                  }
                  html = html.replace(
                    /class="alignCenter"/g,
                    'style="text-align:center;"'
                  );
                  html = html.replace(
                    /class="alignRight"/g,
                    'style="text-align:right;"'
                  );
                  html = html.replace(
                    /<table>/g,
                    '<p><table class="standardTable">'
                  );
                  html = html.replace(
                    /<p>/g,
                    '<p style="font-family:arial;font-size:12pt">'
                  );

                  html = html.replace(/<\/table>/g, "</table></p>");
                  html = html.replace(/<li><p>/g, "<li>");
                  html = html.replace(/<\/p><\/li>/g, "</li>");
                  html = html.replace(/\\/g, "&#92;");
                  html = html.replace(/&amp;nbsp;/g, "&nbsp;<wbr>");
                  html = html.replace(/&amp;#8209;/g, "&#8209;");

                  var messages = result.messages; // Any messages, such as warnings during conversion
                  for (let index = 0; index < messages.length; index++) {
                    if (messages[index].type !== "warning") {
                      toast.error(messages[index].message);
                    }
                  }

                  component.setState({ text: html })
                })
                .done();
            },
            false
          );
        }
      } else {
        evt.target.value = "";
        toast.error("Nur .docx Dateien sind erlaubt");
      }
    }
    document
      .getElementById("files")
      .addEventListener("change", handleFileSelect, false);
  }

  onStartShare = () => {
    var token = window.localStorage.getItem("lb4Token")
    const wsConnection = new WebSocket(config.webSocketUrl + '/api/authorSupport?token=' + token);
    var this2 = this;

    wsConnection.onclose = () => {
      this2.setState({ webSocket: undefined })
      toast.info("Übertragung wurde beendet.")
    }

    wsConnection.onopen = () => {
      this2.setState({ webSocket: wsConnection })
      wsConnection.send("startShare┐" + this.props.textName + "┐" + this.state.chapterId)
      this.sendCurrentTextToWs()
    }

    wsConnection.onmessage = (messageEvent) => {
      var message = messageEvent.data

      if (message === "startShare┐true") {
        toast.info("Übertragung erfolgreich gestartet.")
      }

      if (message === "startShare┐false") {
        toast.info("Beim starten der Übertragung ist ein Fehler aufgetreten.")
        wsConnection.close()
      }

      if (message.startsWith("error")) {
        toast.error(message.slice(6))
      }
    };
  }

  onStopShare = () => {
    if (!this.state.webSocket)
      return;

    this.state.webSocket.close();
  }

  getChapterNameForEdit = (displayName) => {
    return displayName.replace(/­/g, "(-)")
  }

  uploadVideoCallback = (cb, value, meta) => {
    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.mp4');
    var textName = this.props.textName
    var chapterId = this.props.text[0]

    input.onchange = function () {
      var file = this.files[0];

      /*var mbSize = file.size / 1024/ 1000;
      console.log(file.size / 1024/ 1000);

      if(mbSize > 50)
      {
        toast.error("Die Maximalgröße beträgt 50MB.")
        return
      }*/

      let mydata = new FormData();
      mydata.append("File", file);
      mydata.append("textName", textName);
      mydata.append("chapterId", chapterId);
      mydata.append("displayText", "Editor video");
      mydata.append("uploadType", "Editor");

      document.getElementById("progressBar").style.width = "0%";
      $("#progress").text("0%");

      modalInstance.open();

      axios
        .request({
          url: config.apiUrl + "/api/upload",
          method: "POST",
          data: mydata,
          onUploadProgress: p => {
            document.getElementById("progressBar").style.width =
              (p.loaded / p.total) * 100 + "%";
            $("#progress").text(
              Math.floor((p.loaded / p.total) * 100) + "%"
            );
          }
        })
        .then(response => {
          modalInstance.close();
          if (response.status === 201) {
            toast.success("Das Video wurde erfolgreich hochgeladen!");
            cb(response.data.Url)
          } else {
            response.json().then(response => toast.error(response.Message));
          }
        })
        .catch(error => {
          modalInstance.close();
          toast.error(
            "Beim hochladen des Videos ist ein Fehler aufgetreten."
          );
        });
    };

    input.click();
  }

  onVisibleUpdate = () => {
    let isVisible = document.getElementById("visibleCheck").checked
    this.setState({isVisible})
  }

  render() {
    let this2 = this
    const { chapterId, text } = this.state;
    if (chapterId !== this.props.text[0]) {
      document.getElementById("files").value = "";
      this.confirmSave();
      this.setState({
        chapterId: this.props.text[0],
        text: this.props.text[1],
        chapterName: this.props.text[2],
        logoUrl: this.props.logoUrl,
        hasSaved: false,
        isVisible: this.props.text[6]
      });
    }
    return (
      <div>
        <div id="updateProgress" className="modal front" >
          <div className="modal-content">
            <div className="row">
              <h4 className="col s10">Datei wird hochgeladen</h4>
              <h4 id="progress" className="col s2 right-align">
                0%
              </h4>
            </div>
            <div
              className="progress big"
              style={{ height: "25px !important" }}
            >
              <div
                id="progressBar"
                className="determinate"
                style={{ width: "0%", height: "25px" }}
              ></div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "10px" }} className="form-group">
          <div>
            <label className="control-label">
              <b>Kapitel Name</b>
            </label>
            <input
              type="text"
              id="chapterNameInput"
              className="fullInput form-control"
              placeholder="Kapitel Name"
              onChange={this.handleChangeName}
              name="chapterName"
              value={this.getChapterNameForEdit(this.state.chapterName)}
            ></input>
          </div>
          <div className="row">
            <div className="col s6">
              <label className="control-label">
                <b>Logo-Url</b>
              </label>
              <input
                type="text"
                id="logoUrlInput"
                className="fullInput form-control"
                placeholder="Logo-Url"
                onChange={this.handleChangeLogo}
                name="logoUrl"
                value={this.state.logoUrl}
              ></input>
            </div>
            <div className="col s6">
              <label className="control-label">
                <b>Logo-Datei</b>
              </label>
              <input
                type="file"
                id="logoUrlInput"
                className="fullInput form-control"
                placeholder="Logo-Url"
                onChange={this.handleChangeLogoFile}
                name="logoUrl"
              ></input>
            </div>
            <div class="col switch">
              <label>
              <i class="material-icons">visibility_off</i>
                <input id="visibleCheck" type="checkbox" defaultChecked={this.state.isVisible} onChange={this.onVisibleUpdate}/>
                  <span class="lever" style={{top: "-8px"}}></span>
                  <i class="material-icons">visibility</i>
              </label>
            </div>
          </div>
        </div>
        <br />
        <Editor
          value={text}
          onEditorChange={this.onEditorUpdate}
          apiKey="zrpdoc8n1fqo04bn3f8cj1rk0c6roro96pew3hvdkm64v8qi"
          init={{
            setup: editor => {
              editor.ui.registry.addButton("addMouseIcon", {
                tooltip: "Maussymbol einfügen",
                icon: 'mouse',
                onAction: () => editor.insertContent('<span style="font-size: 18px; font-family: Wingdings;" data-mce-style="font-size: 18px; font-family: Wingdings;"><strong>8</strong></span>')
              });
            },
            init_instance_callback: function (editor) {
              this2.setState({ editor });
              editor.on("Dirty", function (e) {
                this2.setState({ hasSaved: false })
              });
            },
            icons_url: document.location.origin + "/icons/lehrbrief-icons/icons.js",
            icons: 'lehrbrief-icons',
            visual: false,
            height: 500,
            body_class: "mce-content-body",
            selector: "textarea",
            content_css: "custom.css,https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0-rc.2/css/materialize.css",
            menubar: false,
            paste_data_images: true,
            images_upload_handler: this.uploadCallback,
            toolbar_drawer: 'sliding',
            image_title: true,
            image_description: false,
            file_picker_types: 'media',
            media_alt_source: false,
            media_poster: false,
            file_picker_callback: this.uploadVideoCallback,
            table_class_list: [
              { title: 'None', value: '' },
              { title: 'Standard', value: 'standardTable' },
            ],
            table_default_attributes: {
              class: 'standardTable'
            },
            contextmenu: "link image imagetools table spellchecker",
            plugins: [
              "advlist autolink lists link image print preview table",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media paste code help wordcount spellchecker autolink charmap",
              "pagebreak paste"
            ],
            toolbar:
              "undo redo | fontselect fontsizeselect formatselect | bold italic underline strikethrough superscript subscript | forecolor backcolor | alignleft aligncenter alignright alignjustify | image media link | bullist numlist outdent indent table charmap addMouseIcon | removeformat code pagebreak | help",
            help_tabs: ["shortcuts", "keyboardnav"],
            color_map,
            charmap_append: [
              [0x0304, 'Kombinierter Überstrich / combined macron'],
            ]
          }}
        />
        <br />
        <div>
          <div>
            <span>Datei hochladen</span>
            <input id="files" name="files[]" type="file" accept=".docx" />
          </div>
        </div>
        <button
          id="save"
          style={{ marginTop: "10px", marginBottom: "30px" }}
          onClick={() => {
            this.onChapterEdit(false);
          }}
          className="btn btn-success"
        >
          Änderungen Speichern
        </button>
        <button
          style={{ marginTop: "10px", marginBottom: "30px" }}
          onClick={() => {
            if (this.state.webSocket) {
              this.onStopShare();
            } else {
              this.onStartShare();
            }
          }}
          className="btn btnMargin btn-danger"
        >
          {
            this.state.webSocket ? "Übertragung deaktivieren" : "Übertragung aktivieren"
          }
        </button>
        <button
          style={{ marginTop: "10px", marginBottom: "30px" }}
          onClick={() => {
            this.onChapterDelete();
          }}
          className="btn btn-danger right"
        >
          Kapitel entfernen
        </button>
      </div>
    );
  }
}


export default TextEditor;
